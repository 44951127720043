import "./video.css";
import videojs from "video.js";
import "videojs-youtube";
import "./video-js.min.css";
import { useEffect, useRef, useState, useCallback } from "react";
import useVideoList from "./captions/captions.js";
import { cipherString } from "./cipher.js";
import useFirestoreDocument from "./firebase/useFirestoreDocument.js";
import langMap from "./LangMap.js";
import Header from "./Header";
import About from "./About";
import config from "./Config.js";
import { WebVTT } from "videojs-vtt.js";
import { Buffer } from "buffer";
import { useNavigate, useLocation } from "react-router-dom";
function VideoList(props) {
  const [currentID, setCurrentID] = useState(0);

  const videoRef = useRef(null);
  const title = config[props.pop_name].header ?? "";
  const description = config[props.pop_name].description ?? [];
  let navigate = useNavigate();
  const location = useLocation();
  const {
    caption: captions_list,
    loading,
    error,
  } = useVideoList(props.pop_name);
  // load srt from firestore
  const {
    documentData: srtFiles,
    loading_srt,
    error_srt,
  } = useFirestoreDocument(props.pop_name, currentID);
  const videoLangMap = captions_list?.reduce((acc, curr) => {
    acc[curr.video_id] = curr.lang;
    return acc;
  }, {});

  // add new video in the ref
  const addVideo = useCallback(
    (captionID) => {
      if (videojs.getAllPlayers().length > 0) {
        videojs.getAllPlayers().forEach((player) => {
          player.dispose();
        });
      }
      if (
        loading ||
        error ||
        (captions_list ?? []).length === 0 ||
        videoRef.current === null ||
        srtFiles === null
      ) {
        return;
      }
      videoRef.current.innerHTML = "";
      const videoElement = document.createElement("video-js");

      videoElement.setAttribute("controls", "");
      videoElement.setAttribute(
        "data-setup",
        '{"fluid": true, "techOrder": ["youtube"]}'
      );
      videoElement.className =
        "video-js vjs-default-skin vjs-big-play-centered";
      videoElement.id = captionID;
      videoRef.current.appendChild(videoElement);
      videojs(
        videoElement,
        {
          techOrder: ["youtube"],
          sources: {
            src: `https://www.youtube.com/watch?v=${captionID}`,
            type: "video/youtube",
          },
        },
        () => {}
      );
      const thumbnail = document.querySelector(".vjs-poster");
      thumbnail.style = `background-image: url(https://img.youtube.com/vi/${captionID}/0.jpg)`;
    },
    [captions_list, error, loading, srtFiles]
  );

  // change player when id changed
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get("id");

    if (id === null || loading || error || (captions_list ?? []).length === 0) {
      return;
    }
    setCurrentID(id);
    fetch(
      `https://noembed.com/embed?dataType=json&url=https://www.youtube.com/watch?v=${id}`
    )
      .then((res) => res.json())
      .then((data) => {
        document.querySelector("#title").textContent = data.title;
      });

    addVideo(id);
  }, [addVideo, captions_list, error, loading, location.search]); // 依赖于location.search，只有它变化时才会重新运行

  // add captions
  useEffect(() => {
    if (currentID === 0 || loading_srt || error_srt || srtFiles === null) {
      return;
    }
    if (srtFiles !== null) {
      const video_name = Object.keys(srtFiles)[0];
      if (!video_name.includes(currentID)) {
        return;
      }
    }
    if (videojs.getAllPlayers().length > 0) {
      videojs.getAllPlayers().forEach((player) => {
        player.ready(() => {
          Object.keys(srtFiles).forEach((lang) => {
            const decoded = cipherString(srtFiles[lang]);
            const video_lang = lang.split(".")[1];

            const track = player.addTextTrack(
              "captions",
              langMap[video_lang].label,
              langMap[video_lang].srclang
            );
            if (lang === "en") {
              track.mode = "showing";
            }
            const parser = new WebVTT.Parser(window, WebVTT.StringDecoder());
            parser.oncue = (cue) => {
              track.addCue(cue);
            };
            parser.parse(Buffer.from(decoded, "base64").toString());
            parser.flush();
          });
          player.load();
        });
      });
    }
  }, [srtFiles, currentID, videoLangMap, loading_srt, error_srt]);

  // click event
  const handleClick = (captionID) => {
    navigate(`?id=${captionID}`);
    if (currentID === captionID) {
      return;
    }

    setCurrentID(captionID);

    fetch(
      `https://noembed.com/embed?dataType=json&url=https://www.youtube.com/watch?v=${captionID}`
    )
      .then((res) => res.json())
      .then((data) => {
        document.querySelector("#title").textContent = data.title;
      });

    addVideo(captionID);
  };

  return loading || error || (captions_list ?? []).length === 0 ? (
    <>loading</>
  ) : (
    <div className={`bg-background grid gap-y-16 overflow-hidden`}>
      <div className={`relative bg-background`}>
        <div className="max-w-7xl mx-auto">
          <div
            className={`relative z-10 pb-8 bg-background sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32`}
          >
            <Header />
          </div>
          <div className="video-section">
            <div className="video-player">
              <h1 id="title" style={{ marginTop: "2rem" }}>
                {" "}
              </h1>
              <div ref={videoRef}>
                <div className="description">
                  <h2 className={`text-2xl text-grey-900`}>{title}</h2>
                  {description.map((item, index) => (
                    <p key={index} className={`text-base text-grey-500`}>
                      {item}
                    </p>
                  ))}
                </div>
              </div>
            </div>

            <div className="video-sidebar">
              {captions_list.map((video, index) => {
                if (video.fans === props.pop_name) {
                  return (
                    <div
                      key={index}
                      id={video.video_id}
                      className="video-item"
                      onClick={() => handleClick(video.video_id)}
                    >
                      {video.title}
                      <br />
                      {video.publish_time}
                      <br />
                      {"Uploaded By: " + video.uploader}
                    </div>
                  );
                }
                return null;
              })}
            </div>
          </div>
        </div>
        <About />
      </div>
    </div>
  );
}

export default VideoList;
