import React from "react";
import { Link } from "react-router-dom";

const MainHero = () => {
  const mainHero = {
    title: "Idol Avenue",
    subtitle: "Ease for fans in one place",
    description:
      "Idol Avenue aims at providing fans with an ecosystem that make the fans lives easier. Right now we provide a curated collection of the translated videos from your favorite artists. We are dedicated to revolutionizing the way you experience video content online. As avid video enthusiasts ourselves, we understand the importance of quality subtitles for an immersive viewing experience. Our mission is to empower subtitle authors while enhancing accessibility and convenience for viewers.",
    primaryAction: {
      text: "Email Us",
      href: "mailto:inquires@idolavenue.com",
    },
  };
  return (
    <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
      <div className="sm:text-center lg:text-left">
        <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
          <span className="block xl:inline">{mainHero.title}</span>{" "}
          <span className={`block text-primary xl:inline`}>
            {mainHero.subtitle}
          </span>
        </h1>
        <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
          {mainHero.description}
        </p>
        <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
          <div className="rounded-md shadow">
            <Link
              to={mainHero.primaryAction.href}
              className={`w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-background bg-primary hover:bg-border hover:text-primary md:py-4 md:text-lg md:px-10`}
            >
              {mainHero.primaryAction.text}
            </Link>
          </div>
        </div>
      </div>
    </main>
  );
};

export default MainHero;
