import useFirestoreDocument from "../firebase/useFirestoreDocument";
import { useState, useEffect } from "react";
const useVideoList = (collection_name) => {
  const [caption, setCaption] = useState([]);
  const {
    documentData: caption_list,
    loading,
    error,
  } = useFirestoreDocument(collection_name, "video_list");

  useEffect(() => {
    if (caption_list?.data.length > 0 && !loading && error === null) {
      setCaption(caption_list?.data);
    }
  }, [caption_list, loading, error]);

  return { caption, loading, error };
};

export default useVideoList;
