import { useState, useEffect } from "react";
import { doc, getDoc } from "firebase/firestore";
import db from "./firebase"; // Adjust the path based on your file structure
const useFirestoreDocument = (collectionName, documentId) => {
  const [documentData, setDocumentData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDocument = async () => {
      try {
        const docRef = doc(db, collectionName, documentId);
        const docSnapshot = await getDoc(docRef);
        if (docSnapshot.exists) {
          setDocumentData(docSnapshot.data());
        } else {
          setDocumentData(null);
          setError("Document does not exist");
        }
      } catch (err) {
        console.error("Error fetching document: ", err);
        setError(err.message);
        setDocumentData(null);
      } finally {
        setLoading(false);
      }
    };

    if (documentId) {
      fetchDocument();
    }
  }, [collectionName, documentId]);

  return { documentData, loading, error };
};

export default useFirestoreDocument;
