import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ErrorPage from "./ErrorPage";
import VideoList from "./VideoList";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBlZ7OhSjfIr4xETWZpTJMSFSGxHOpv0Uc",
  authDomain: "idolaveune.firebaseapp.com",
  projectId: "idolaveune",
  storageBucket: "idolaveune.appspot.com",
  messagingSenderId: "242307059890",
  appId: "1:242307059890:web:94de0004937b9379a7f9ab",
  measurementId: "G-YFFKZHHBF1",
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/zb1",
    element: <VideoList pop_name="zb1" />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/enhypen",
    element: <VideoList pop_name="enhypen" />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/evnne",
    element: <VideoList pop_name="evnne" />,
    errorElement: <ErrorPage />,
  },
]);
// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
