import React from "react";

const MainHeroImage = () => {
  const mainHero = {
    img: "./hero_image.png",
  };
  return (
    <div className="inset-y-0 right-0 w-1/3 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
      <img
        className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-50 lg:h-full"
        src={mainHero.img}
        alt="idol "
      />
    </div>
  );
};

export default MainHeroImage;
