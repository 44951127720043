// import buffer
import { Buffer } from "buffer";

export function cipherString(data) {
  let buff = Buffer.from(data, "base64");

  // Reverse the bytes
  let reversedBuff = Buffer.from(buff).reverse();
  //xor with 0xFF
  for (let i = 0; i < reversedBuff.length; i++) {
    reversedBuff[i] = reversedBuff[i] ^ 0xff;
  }
  // Convert buffer to base64 string
  let base64 = reversedBuff.toString("base64");
  return base64;
}
