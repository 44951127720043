const langMap = {
  en: { srclang: "en", label: "English" },
  Arabic: { srclang: "ar", label: "Arabic" },
  French: { srclang: "fr", label: "French" },
  cn: { srclang: "zh-CN", label: "Chinese (Simplified)" },
  Filipino: { srclang: "fil", label: "Filipino" },
  Hindi: { srclang: "hi", label: "Hindi" },
  Indonesian: { srclang: "id", label: "Indonesian" },
  jp: { srclang: "ja", label: "Japanese" },
  ja: { srclang: "ja", label: "Japanese" },
  Malay: { srclang: "ms", label: "Malay" },
  Portuguese: { srclang: "pt", label: "Portuguese" },
  Spanish: { srclang: "es", label: "Spanish" },
  Thai: { srclang: "th", label: "Thai" },
  Vietnamese: { srclang: "vi", label: "Vietnamese" },
  Russian: { srclang: "ru", label: "Russian" },
  ko: { srclang: "ko", label: "Korean" },
  German: { srclang: "de", label: "German" },
};
export default langMap;
