import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
const firebaseConfig = {
  apiKey: "AIzaSyBlZ7OhSjfIr4xETWZpTJMSFSGxHOpv0Uc",
  authDomain: "idolaveune.firebaseapp.com",
  projectId: "idolaveune",
  storageBucket: "idolaveune.appspot.com",
  messagingSenderId: "242307059890",
  appId: "1:242307059890:web:94de0004937b9379a7f9ab",
  measurementId: "G-YFFKZHHBF1",
};

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);

export default db;
