const config = {
  logo: "./idol.png",
  company: "Idol Avenue",
  navigation: ["ZB1", "enhypen", "evnne"],
  about: {
    socialMedia: {
      twitter: "https://twitter.com/IDOL_AVENUE",
    },
  },
  zb1: {
    description: [
      "KPOP Groups we currently support. Contact us if you want to get content from other groups.",
      "Zerobaseone is a South Korean boy band formed through Mnet's reality competition Boys Planet in 2023. They debuted on July 10, 2023",
      " The group is composed of nine members: Kim Ji-woong, Zhang Hao, Sung Han-bin, Seok Matthew, Kim Tae-rae, Ricky, Kim Gyu-vin, Park Gun-wook and Han Yu-jin. 🚀",
    ],
    header: "Welcome to zerobaseone!",
  },
  enhypen: {
    description: ["Coming Soon!"],
    header: "Welcome to Enhypen!",
  },
  evnne: {
    description: ["Coming Soon!"],
    header: "Welcome to Evnne!",
  },
};
export default config;
