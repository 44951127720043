import React from "react";
import { Link } from "react-router-dom";
import config from "./Config";
const NavBar = () => {
  const { navigation, logo, company } = config;

  return (
    <div className="relative pt-6 px-4 sm:px-6 lg:px-8">
      <nav
        className="relative flex items-center justify-between sm:h-10 lg:justify-start"
        aria-label="Global"
      >
        <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
          <div className="flex items-center justify-between w-full md:w-auto">
            <a href="/">
              <span className="sr-only">{company}</span>
              <img alt="logo" className="h-16 w-auto sm:h-16" src={logo} />
            </a>
          </div>
        </div>
        <div className="flex item-center md:ml-10 md:pr-4 md:space-x-8">
          {navigation.map((item) => (
            <Link
              key={item}
              to={`/${item}`}
              className={`block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50`}
            >
              {item}
            </Link>
          ))}
        </div>
      </nav>
    </div>
  );
};

export default NavBar;
