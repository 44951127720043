import React from "react";
import Header from "./Header";
import MainHero from "./MainHero";
import MainHeroImage from "./MainHeroImage";
import "./output.css";
import About from "./About";
const App = () => {
  return (
    <div className={`bg-background`}>
      <div className={`grid gap-y-16 overflow-hidden`}>
        <div className={`relative bg-background`}>
          <div className="max-w-7xl mx-auto">
            <div
              className={`relative z-10 pb-8 bg-background sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32`}
            >
              <Header />
              <MainHero />
            </div>
            <MainHeroImage />
          </div>
        </div>
      </div>
      <About />
    </div>
  );
};
export default App;
